.navigation-links {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-bottom: 3rem;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 3.5rem;
  left: 0;
  width: 100%;

  .navbar-nav {
    padding-inline-start: 0;
  }

  li.nav-item {

    a {
      display: inline-block;
      font-size: 1.5rem;
      text-decoration: none;
      color: $white;
      padding: 1rem 1.5rem;
      border-bottom: 1px solid var(--border-color);

      &.active {
        border-bottom: 2px solid var(--main-btn-bg);
        color: var(--main-btn-bg);
        font-weight: 600;
      }
    }
  }
}

select.lang-select {
  // padding: 1rem 1.5rem;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  outline: none;
  option {
    background-color: transparent;
    color: #000;
  }
}