.not-registered {
  padding: 0 5rem;

  &-header {
    text-align: center;

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  &-actions {
    display: flex;
    width: 100%;
    margin-top: 3.5rem;
    flex-direction: column;
    row-gap: 2.5rem;
  }

  &-cancel {
    text-align: center;
  }
}

.subscriptionType {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid var(--singleRecordCardBorder);
  // border-radius: 4px;
  cursor: pointer;

  &.selected {
    border-color: var(--main-btn-bg);
    /* Border color when selected */
  }

  input[type='radio'] {
    display: none;
  }

  label {
    margin-right: 10px;
    cursor: pointer;
  }
}

.zain-login {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.zain-login-input input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid var(--singleRecordCardBorder);
  background-color: transparent;
  color: var(--singleRecordCardBorder);
  font-size: 1.2rem;
}