$green: #65990c;
$du-blue: #0086c3;
$orange: #ff9d18;
$stc-purple: #4f008c;
$yellow: #9db30e;
$tajawal: "Tajawal";
$quran: "Quran";
$light-green: #69a404;
$bg-dark: #0a0806;
$bg-dark-lighter: #1a1918;
$bg-light: #f4f4f4;
$white: #FFF;
$error: #eb1405;
$light-gray: #707070;
$dark-white: #efefef;
$black: #000;
$main-color: $green;
$gray-300: #1f1f1f;
$gray-700: #b9b9b9;
$du-theme: (
    "main-color": $du-blue,
    "gradient-main-dark": darken($du-blue, 30),
    "gradient-main-light": darken($du-blue, 20),
    "main-icon-color": $du-blue,
    "main-btn-bg": $du-blue,
    "white-btn-color": $du-blue,
    "play-pause-btn": lighten($du-blue, 5),
    "dark-main-color": darken($du-blue, 5)
);
$zain-theme: (
    "main-color": $green,
    "gradient-main-dark": darken($green, 30),
    "gradient-main-light": darken($green, 20),
    "main-icon-color": $green,
    "main-btn-bg": $green,
    "white-btn-color": $green,
    "play-pause-btn": lighten($green, 5),
    "dark-main-color": darken($green, 5)
);
$omantel-theme: (
    "main-color": $orange,
    "gradient-main-dark": darken($orange, 30),
    "gradient-main-light": darken($orange, 20),
    "main-icon-color": $orange,
    "main-btn-bg": $orange,
    "white-btn-color": $orange,
    "play-pause-btn": lighten($orange, 5),
    "dark-main-color": darken($orange, 5)
);
$stc-theme: (
    "main-color": $stc-purple,
    "gradient-main-dark": darken($stc-purple, 30),
    "gradient-main-light": darken($stc-purple, 20),
    "main-icon-color": $stc-purple,
    "main-btn-bg": $stc-purple,
    "white-btn-color": $stc-purple,
    "play-pause-btn": lighten($stc-purple, 5),
    "dark-main-color": darken($stc-purple, 5)

);
$main-colors: (
    "icon-color": $yellow,
    "btn-color": $light-green,
    "main-font-family": $tajawal,
    "quran-font-family": $quran,
    "singleRecordCardBorder": $light-gray,
    "carousel-color": $white,
    "input-color": $dark-white,
    "secondary-icon-color": $white,
    "secondary-btn-bg": $light-gray,
    "white-btn-bg": $white,
    "secondary-btn-color": $white,
    "main-btn-color": $white,
    "error-color": $error,
);
$dark-vars: (
    "main-bg": $bg-dark,
    "dark-bg": $bg-dark-lighter,
    "comments-bg": $bg-light,
    "text-color": $white,
    "footerBg": $bg-dark-lighter,
    "avatar-bg": $dark-white,
    "border-color": $light-gray,
    "white-border": $white,
    "comment-bg": #302f2f,
    "comment-color": #909090,
    "icon-light-gray-bg": #42444c,
    "post-bg": $bg-dark-lighter,
    "single-record-gradient": $black,
    "popup-bg": $gray-300
);
$light-vars: (
    "main-bg": $bg-light,
    "dark-bg": $light-gray,
    "comments-bg": $bg-light,
    "text-color": $bg-dark-lighter,
    "footerBg": $dark-white,
    "avatar-bg": $light-gray,
    "border-color": $bg-dark-lighter,
    "white-border": $black,
    "comment-bg": $dark-white,
    "comment-color": $bg-dark-lighter,
    "icon-light-gray-bg": #42444c,
    "post-bg": darken($dark-white, 5),
    "single-record-gradient": $white,
    "popup-bg": $gray-700
);
// $grid-gutter-width : 10rem;